import React from 'react'

import Layout from '@/components/Layout'
import Seo from '@/components/Seo'
import WildMagicGenerator from '@/components/WildMagicGenerator'

export default () => (
  <Layout>
    <Seo
      title='Генератор дикой магии для D&D'
    />
    <WildMagicGenerator/>
  </Layout>
)
