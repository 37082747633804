import React from 'react'

import wildMagicEffectList from '@/constants/wildMagicEffectList'

import WildMagicGeneratorComponent from './WildMagicGeneratorComponent'
import trackAnalyticEvent from '@/utils/trackAnalyticEvent'

class WildMagicGeneratorContainer extends React.Component {
  generateHook = () => {
    trackAnalyticEvent('generate-wild-magic')
  }

  render() {
    return (
      <WildMagicGeneratorComponent
        generateHook={this.generateHook}
        list={wildMagicEffectList}
      />
    )
  }
}

export default WildMagicGeneratorContainer
