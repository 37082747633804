import React from 'react'

import AdsAtGenerator from '@/ads/components/AdsAtGenerator'
import SimpleTableGenerator from '@/components/SimpleTableGenerator'

const WildMagicGeneratorComponent = (props) => (
  <section className='WildMagicGenerator'>
    <p>Генератор на основе таблиц из <a href="http://www.angelfire.com/rpg2/vortexshadow/magic/unstablemagic.html">Totally Random Magical Effects Table</a> и <a href="https://centralia.aquest.com/downloads/NLRMEv2.pdf">The Net Libram of Random Magical Effects</a> (перевод на русский в процессе).</p>
    <SimpleTableGenerator
      showDiceRolls={false}
      title='Дикая магия'
      {...props}
    />
    <AdsAtGenerator/>
  </section>
)

export default WildMagicGeneratorComponent
