// http://www.angelfire.com/rpg2/vortexshadow/magic/unstablemagic.html
// https://centralia.aquest.com/downloads/NLRMEv2.pdf
import wildMagicEffectListRaw_done from './wildMagicEffectListRaw_done.json'
import wildMagicEffectListRaw_todo from './wildMagicEffectListRaw_todo.json'

const wildMagicEffectList = [
	...wildMagicEffectListRaw_done,
	...wildMagicEffectListRaw_todo,
].map(
	(text, i) => ({
		description: text,
		id: i,
		probabilityWeight: 1,
	})
)

export default wildMagicEffectList
